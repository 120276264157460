import { BrickElement, defineCustomElement } from '@amedia/brick-template';

import type { BrickTeaserPlayerData } from './types.js';
import { brickTeaserPlayerTemplate, sroStyle } from './template';
import { componentStyle } from './styles.js';
import '@amedia/brick-image';
import '@amedia/brick-button';

@defineCustomElement({
  selector: 'brick-teaser-player',
})
export class BrickTeaserPlayer
  extends BrickElement
  implements BrickTeaserPlayerData, HTMLElement
{
  dataTitle?: string;
  dataMediaId?: string;
  dataPlayerId?: string;
  dataAspectRatio?: string;
  dataAutoplay?: string;
  dataStart?: string;
  dataNext?: string;
  dataNextAcpId?: string;
  dataNextTitle?: string;
  dataNextPosterUrl?: string;
  dataNextDuration?: string;
  dataNextPremium?: string;
  dataNextPermalink?: string;
  dataMuted?: string;
  #_overlay?: HTMLElement | null;
  #_playButton?: HTMLElement | null;
  #closeButton?: HTMLElement | null;
  #focusElement?: HTMLElement | null;
  #posterLink?: HTMLElement | null;

  constructor() {
    super();
    this.handleEvent = this.handleEvent.bind(this);
    this.handlePlayButtonClick = this.handlePlayButtonClick.bind(this);
    this.handleCloseButtonClick = this.handleCloseButtonClick.bind(this);
    this.handleEscapeKey = this.handleEscapeKey.bind(this);
    this.#_overlay = null;
    this.#_playButton = null;
    this.#closeButton = null;
    this.#focusElement = null;
    this.#posterLink = null;
  }

  static get mirroredProps() {
    return [
      'data-title',
      'data-media-id',
      'data-player-id',
      'data-aspect-ratio',
      'data-autoplay',
      'data-ads-disabled',
      'data-next',
      'data-next-acp-id',
      'data-next-title',
      'data-next-poster-url',
      'data-next-duration',
      'data-next-permalink',
      'data-next-premium',
      'data-muted',
      'data-start',
    ];
  }

  async connectedCallback() {
    this.classList.add(`${componentStyle}`);
    super.connectedCallback();
    this.addEventListener('brick-player:playingstate', (event) => {
      const customEvent = event as CustomEvent;
      this.handleEvent(customEvent);
    });
    requestAnimationFrame(() => {
      this.handleOverlayElements();
      this.#overlay?.querySelector('span.sro')?.classList.add(sroStyle());
    });
  }

  handleOverlayElements() {
    if (this.#playButton && this.#closeButton && this.#posterLink) {
      return;
    }
    this.getOverlayElements();
  }

  handleEvent(event: CustomEvent) {
    const { state } = event.detail;
    const useElement = this.#playButton?.querySelector('use');
    useElement?.setAttribute('href', state === 'pause' ? '#play' : '#replay');
    const usePauseEvent = !['true', 'trueIfInteractive'].includes(
      this.dataAutoplay || ''
    );
    if (usePauseEvent && state === 'pause' && this.dataset.next !== 'true') {
      this.setAttribute('data-next', 'true');
      this.#focusElement = this.#playButton;
    }
    if (state === 'end') {
      this.setAttribute('data-next', 'true');
      this.#focusElement = this.#posterLink;
    }
    if (state === 'play') {
      this.setAttribute('data-next', 'false');
    }
  }

  handleEscapeKey(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.hideOverlay();
    }
  }

  toggleOverlay(show) {
    if (!this.#overlay) return;
    this.handleOverlayElements();
    this.#overlay.classList.toggle('recommended-visible', show);
    this.querySelector('brick-player')?.toggleAttribute('inert', show);
    this.handleEventListeners(show);
  }

  handleEventListeners(show: boolean) {
    if (show) {
      if (this.#playButton) {
        this.#playButton.addEventListener('click', this.handlePlayButtonClick);
      }

      if (this.#closeButton) {
        this.#closeButton.addEventListener(
          'click',
          this.handleCloseButtonClick
        );
      }
      document.addEventListener('keydown', this.handleEscapeKey.bind(this));
    } else {
      this.removeEventListeners();
    }
  }

  removeEventListeners() {
    if (this.#playButton) {
      this.#playButton.removeEventListener('click', this.handlePlayButtonClick);
    }

    if (this.#closeButton) {
      this.#closeButton.removeEventListener(
        'click',
        this.handleCloseButtonClick
      );
    }
    // Remove new event listeners
    document.removeEventListener('keydown', this.handleEscapeKey.bind(this));
  }

  handlePlayButtonClick() {
    this.replay();
  }
  handleCloseButtonClick() {
    this.hideOverlay();
  }

  hideOverlay() {
    this.setAttribute('data-next', 'false');
  }

  replay() {
    this.hideOverlay();
    const player = this.querySelector('brick-player') as any;
    (player as any)?.play();
    this.focus();
  }

  async disconnectedCallback() {
    this.removeEventListeners();
  }

  get #overlay(): HTMLElement | null {
    return this.#_overlay || null;
  }

  set #overlay(value: HTMLElement | null | undefined) {
    this.#_overlay = value ?? null;
    if (value) {
      this.setAttribute('has-next-overlay', 'true');
    } else {
      this.removeAttribute('has-next-overlay');
    }
  }

  get #playButton(): HTMLElement | null {
    return this.#_playButton || null;
  }

  set #playButton(value: HTMLElement | null | undefined) {
    this.#_playButton = value ?? null;
  }

  static get observedAttributes() {
    return ['data-next', 'has-next-overlay'];
  }

  attributeChangedCallback(attr: string, val: string, newValue: string) {
    if (attr && val === newValue) return;
    switch (attr) {
      case 'data-next':
        this.toggleOverlay(newValue === 'true');
        break;
      case 'has-next-overlay':
        if (newValue === 'true') {
          this.toggleOverlay(this.dataNext === 'true');
        }
        break;

      default:
    }
  }

  getOverlayElements() {
    this.#overlay =
      (this.querySelector('[data-recommend-overlay]') as HTMLElement) || null;
    this.#playButton =
      (this.querySelector('brick-button-v7.replay') as HTMLElement) || null;
    this.#closeButton =
      (this.querySelector(
        'brick-button-v7[data-iconid="close"]'
      ) as HTMLElement) || null;
    this.#posterLink = (this.querySelector('a') as HTMLElement) || null;
  }

  get HTML() {
    return brickTeaserPlayerTemplate({
      dataTitle: this.dataTitle || '',
      dataMediaId: this.dataMediaId || '',
      dataPlayerId: this.dataPlayerId || '',
      dataAspectRatio: this.dataAspectRatio || '1:1',
      dataStart: this.dataStart || '',
      dataNext: this.dataNext || '',
      dataNextAcpId: this.dataNextAcpId || '',
      dataNextTitle: this.dataNextTitle || '',
      dataNextPosterUrl: this.dataNextPosterUrl || '',
      dataNextDuration: this.dataNextDuration || '',
      dataNextPermalink: this.dataNextPermalink || '',
      dataAutoplay: this.dataAutoplay || 'false',
      dataMuted: this.dataMuted || 'true',
    });
  }
}
